import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components'
import { fadeInUp } from '../utils/m-styled-animations'
import Video from './common/video'


const ProjectStyle = styled.div`
    max-width: 95%;
    width: 80ch;
    margin: 3rem auto;
    padding-top: 1.2rem;
    button{
        color: ${({ theme }) => theme.text};
        background: ${({ theme }) => theme.body};
        border: 0.5px solid ${({ theme }) => theme.text};
        border-radius: 3px;
        padding: 13px 16px;
        margin-top: 1rem;
        font-size: .7rem;
    }    
`

const ImageGrid = styled.div`
    display: grid;
    animation: ${fadeInUp} 1.8s;
    grid-row-gap: .6rem;
    p {
        opacity: 0.85;
    }
    Img {
        border-radius: 5px;
    }
    @media (min-width: 750px) {
        grid-column: 1;
    }
`

const ProjectHeader = styled.div`
    display: grid;
    grid-template-rows: 10px auto auto;
    animation: ${fadeInUp} 1.8s;

    p {
        opacity: 0.93;
    }
    h1 {
        font-size: 1.7rem;
    }
    @media (min-width: 750px) {
    }

`
const DescriptionContainer = styled.div`
    margin-top:-.7rem;
    padding-bottom: 1rem;
`
const PillContainer = styled.div`
    display: flex;
    margin-left: -3px;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    padding-top: 1rem;    
    span {
        color: ${({ theme }) => theme.tagtext};
        background: ${({ theme }) => theme.tag};
        padding: 0px 3px;
        border-radius: 3px;
        text-transform: uppercase;
        letter-spacing: 0px;
        font-size: 0.4rem;
        font-weight: 600;
        line-height: 165%;
        margin: 0 3px;
    }
`;

const Videoproject = ({ title, description, videoData, imageData, tags, category }) => {
    return (
        <ProjectStyle>
            <ProjectHeader>
                <PillContainer>
                    {tags.map((tag) => {
                        return (
                            <span key={tag}>{tag}</span>
                        )
                    })}
                </PillContainer>
                <h1>{title}</h1>
                <DescriptionContainer>
                    {description}
                </DescriptionContainer>
            </ProjectHeader>

            <ImageGrid>
                {videoData &&
                    videoData.map((video) => <Video key={video} videoSrcURL={`${video}?autoplay=0&origin=http://example.com`}
                        videoTitle={title} />)
                }
                {imageData &&
                    imageData.map((image) => <Img key={image.id} fluid={image.fluid} alt={title} />)
                }
            </ImageGrid>

            <button>
                <Link to="/">&larr; back to all projects</Link>
            </button>
        </ProjectStyle>
    )
}

export default Videoproject;
