import React from "react"
import styled from 'styled-components'

const VideoWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding: 0 10px;
    justify-items:center;
    position: relative;
    margin: 10px 0px;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    /* https://css-tricks.com/fluid-width-video/ - sursa pentru ratio */
    
    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
`

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
    <VideoWrap>
        <iframe
            src={videoSrcURL}
            title={videoTitle}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
        />
    </VideoWrap>
)
export default Video